@charset "utf-8";

/* RESET
--------------------------------------------------*/
html, body, h1, h2, h3, h4, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	font-weight: normal;
	font-size: 100%;
	vertical-align: baseline;
}

article, header, footer, aside, figure, figcaption, nav, section {
	display: block;
}

body {
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

ol, ul {
	list-style: none;
	list-style-type: none;
}

li {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

table tr th,
table tr td {
	padding: 10px 0;
	text-align: left;
	vertical-align: middle;
	border: 1px solid #efefef;
	border-collapse: collapse;
}

img, video {
	vertical-align: bottom;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}





/* カスタマイズ
--------------------------------------------------*/
html {
	line-break: strict;
}

body {
  font-family: 'AppleSystem', 'Yu Gothic', 'HiraKakuProN-W3','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, Osaka, 'MS UI Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  //font-family: '游明朝', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', serif;
  color: $color_font;
	letter-spacing: 0.07em;
	line-height: 1;
	background-color: $color_bg;
	text-rendering: optimizeLegibility;
  font-feature-settings: 'palt' 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: $color_link;
	text-decoration: none;
	overflow: hidden;
	outline: none;
}
a:hover { color: $color_link_over; }
a:focus, *:focus { outline: none; }
a:link { -webkit-tap-highlight-color: rgba(204, 204, 204, 0.4); }
::-moz-selection{ background: $color_select; color: #ffffff; text-shadow: none; }
::selection { background: $color_select; color: #ffffff; text-shadow: none; }

p {
	margin-bottom: 0;
	line-height: 2;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

.win h1,
.win h2,
.win h3,
.win h4,
.win h5,
.win h6,
.win p,
.win span,
.win a,
.win dt,
.win dd,
.win th,
.win td,
.win small,
.win time,
.win label,
.win input,
.win textarea,
.win option,
.win select {
  font-weight: 500;
}

/* clearfix */
.clearfix {zoom:1;}
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

/* リスト要素の横組みレイアウト */
.list>li {
  float: left;
}
.list.right>li {
  float: right;
}

/* カラム要素の横組みレイアウト */
.row>.column {
  float: left;
}
.row>.column.right {
  float: right;
}


/* フロート要素を中央配置（PC版） */
.pc .float_center_pc {
	position: relative;
	overflow: hidden;
}
.pc .float_center_pc>ul {
	position: relative;
	left: 50%;
	float: left;
}
.pc .float_center_pc>ul>li {
	position: relative;
	left: -50%;
	float: left;
}

/* フロート要素を中央配置（SP版） */
.sp .float_center_sp {
	position: relative;
	overflow: hidden;
}
.sp .float_center_sp>ul {
	position: relative;
	left: 50%;
	float: left;
}
.sp .float_center_sp>ul>li {
	position: relative;
	left: -50%;
	float: left;
}

/* 天地左右の中央配置 */
.tblr_center {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* 天地の中央配置 */
.tb_center {
  position: relative;
  top: 50%;
	width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* スクロールトップボタン */
.scrolltop {
	position: fixed;
	bottom: 40px;
	right: 40px;
	cursor: pointer;
}
.scrolltop:hover {
	opacity: 0.6;
}

/* 先頭文字の字下げ */
.indent {
  padding-left: 1em;
  text-indent: -1em;
}

/* 改行の切り分け */
.pc .sp_only,
.sp .pc_only {
	display: none;
}

/* 遅延読み込みの演出 */
.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

/* YouTubeプレイヤーのレスポンシブ化 */
.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

/* スクロール時にフェードインで表示 */
.fade_container {
	opacity: 0;
}

/* スクロール位置に合わせて固定化 */
.following {
  position: relative;
}
.following_container.fixed,
.following_container.absolute {
  height: 1px;
}
.following_container.fixed .following_content {
  position: fixed;
  top: 200px;
  left: 50%;
  width: 200px;
}
.sp .following_container.fixed .following_content {
  position: static;
  top: auto;
  left: auto;
  width: 100%;
}
.following_container.absolute .following_content {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 200px;
}

/* 画像をフルスクリーン表示 */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fullscreen .fullscreen_content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

/* パララックス */
.parallax_container {
	overflow: hidden;
}

/* 高さ揃えのデータリスト */
.data_list {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
}

.data_list dt {
  float: left;
  width: 130px;
  padding: 10px;
  border-top: 1px solid #efefef;
}

.data_list dd {
  width: calc(100% - 130px);
  margin-left: 130px;
  padding: 10px;
  border-top: 1px solid #efefef;
}

/* アコーディオン */
.accordion dt {
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}
.accordion dt:hover {
	opacity: 0.5;
	transition: opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.accordion dd {
	display:none;
}


/* メニューボタン */
.menu {
	display: none;
	position: fixed;
	top: 10px;
	right: 20px;
	width: 44px;
	height: 44px;
	padding: 10px;
	z-index: 9999;
	cursor: pointer;
}
.sp .menu {
	display: block;
	top: 0;
  left: 0;
	padding: 15px;
}

.menu .menu_bar {
	display: block;
	width: 24px;
	height: 2px;
	margin-bottom: 6px;
	background-color: #000000;
}
.menu .menu_bar:last-child {
  margin-bottom: 0;
}

.menu,
.menu .menu_bar {
	display: inline-block;
	transition: all .4s;
}

.menu .menu_bar {
	position: absolute;
	left: 11px;
	width: 22px;
	height: 1px;
	background-color: #000000;
}
.menu .menu_bar:nth-of-type(1) {
	top: 14px;
}
.menu .menu_bar:nth-of-type(2) {
	top: 21px;
}
.menu .menu_bar:nth-of-type(3) {
	top: 28px;
}


/* メニューアニメーション */
.menu .menu_bar:nth-of-type(1) {
	animation: menu_bar1 .45s forwards;
}
@keyframes menu_bar1 {
	  0% { transform: translateY(7px) rotate(45deg); }
	 50% { transform: translateY(7px) rotate(0); }
	100% { transform: translateY(0) rotate(0); }
}

.menu .menu_bar:nth-of-type(2) {
	transition: all .15s .15s;
	opacity: 1;
}

.menu .menu_bar:nth-of-type(3) {
	animation: menu_bar2 .45s forwards;
}

@keyframes menu_bar2 {
	  0% { transform: translateY(-7px) rotate(-45deg); }
	 50% { transform: translateY(-7px) rotate(0); }
	100% { transform: translateY(0) rotate(0); }
}

.menu.active span:nth-of-type(1) {
	animation: active_menu_bar1 .45s forwards;
}
@keyframes active_menu_bar1 {
	  0% { transform: translateY(0) rotate(0); }
	 50% { transform: translateY(7px) rotate(0); }
	100% { transform: translateY(7px) rotate(45deg); }
}

.menu.active span:nth-of-type(2) {
	opacity: 0;
}

.menu.active span:nth-of-type(3) {
	animation: active_menu_bar3 .45s forwards;
}
@keyframes active_menu_bar3 {
	  0% { transform: translateY(0) rotate(0); }
	 50% { transform: translateY(-7px) rotate(0); }
	100% { transform: translateY(-7px) rotate(-45deg); }
}



/* フォーム */
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #999999;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
}
::placeholder { /* Others */
	color: #999999
}
