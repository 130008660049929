@charset "utf-8";

/*!
 * Theme Name: 5ive theme
 * Theme URI: https://5ive.jp/
 * Description: 5ive専用のベーステーマ
 * Author: 5ive Inc.
 * Url: https://5ive.jp/
 * Version: 2.3
 */



/* アセット関連
--------------------------------------------------*/
@import './assets/setting';
@import './assets/tool/mixin';
@import './assets/module/normalize';
@import './assets/module/font';
@import './assets/module/base';





/* ライブラリ関連
--------------------------------------------------*/
@import './libs/swiper';





/* 共通要素
--------------------------------------------------*/
html {
	height: 100%;
}

body {
	height: 100%;
	background-color: #ccc;
}
body.is_menu_open {
  overflow: hidden;
}

.wrapper {

}

article {

}

.container1 {
	width: 980px;
	margin: 0 auto;
}
.sp .container1 {
	width: 100%;
}

.container2 {
	width: 720px;
	margin: 0 auto;
}
.sp .container2 {
	width: 100%;
}

.page_title {
  margin-bottom: 30px;
  font-size: 20px;
  letter-spacing: 0.5em;
  line-height: 1.94;
}

.sec_title {
  margin-bottom: 15px;
  font-size: 18px;
  letter-spacing: 0.15em;
  line-height: 1.75;
}





/* HEADER
--------------------------------------------------*/
header {
  position: relative;
  z-index: 9000;
}

header .logo {

}
header .logo a:hover {
  opacity: 0.6;
}

header .gnav_container {

}
.sp header .gnav_container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 15px;
  background-color: rgba(0,0,0,0.5);
  z-index: 2000;
}

header .gnav_container li {
  float: left;
  margin-left: 10px;
}
header .gnav_container li:first-child {
  margin-left: 0;
}
.sp header .gnav_container li {
  float: none;
  margin-left: 0;
}





/* TOP PAGE
--------------------------------------------------*/
.top_page {

}





/* FOOTER
--------------------------------------------------*/
footer {

}

footer .footer_container {
  position: relative;
  width: 980px;
  margin: 0 auto;
}

footer .copyright {
  font-size: 12px;
}





/* WordPress関連
--------------------------------------------------*/
.current_nav,
.current-cat a {
  opacity: 0.6;
}

.wp-pagenavi {
  margin: 60px 0 100px 0;
}

.wp-pagenavi span,
.wp-pagenavi a {
  margin-left: 20px;
  padding: 10px 5px 5px 5px;
  font-size: 16px;
}
.wp-pagenavi span:first-child,
.wp-pagenavi a:first-child {
  margin-left: 0;
}

.wp-pagenavi .current {
  border-bottom: 1px solid #000000;
}

.controller {
  position: relative;
  width: 100%;
  height: 20px;
}

.controller span a:hover {
  opacity: 0.4;
}

.controller_prev {
  position: absolute;
  top: 0;
  left: 0;
}
.sp .controller_prev {
  position: static;
  top: auto;
  left: auto;
  margin-bottom: 20px;
  display: block;
}

.controller_prev a {
  padding-left: 20px;
  font-size: 14px;
  font-weight: bold;
  background-image: url('img/icon_prev.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 9px 18px;
}
.sp .controller_prev a {
  font-size: 12px;
}

.controller_next {
  position: absolute;
  top: 0;
  right: 0;
}
.sp .controller_next {
  position: static;
  top: auto;
  right: auto;
}

.controller_next a {
  padding-right: 20px;
  font-size: 14px;
  font-weight: bold;
  background-image: url('img/icon_next.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 9px 18px;
}
.sp .controller_next a {
  padding-right: 0;
  padding-left: 20px;
  font-size: 12px;
  background-position: left center;
}

.controller_close a {
  display: inline-block;
  width: 44px;
  height: 44px;
  text-indent: -9999px;
  background-image: url('img/icon_close.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 17px 17px;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 7px 10px 7px 10px;
  font-size: 16px;
  line-height: 1.75;
  color: #000000;
  border-radius: 0;
  border: none;
  background-color: #f0f0ea;
  -webkit-appearance: none;
}
textarea {
	height: 200px;
  margin-bottom: 50px;
  padding: 10px 10px 15px 10px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

input[type="submit"] {
  display: block;
  width: 100%;
  margin: -20px auto 10px auto;
  padding: 22px 10px 19px 10px;
  font-size: 15px;
  color: #FFFFFF;
  letter-spacing: 0.075em;
	border: none;
  border-radius: 0;
  background-color: #000000;
  -webkit-appearance: none;
	cursor: pointer;
}
input[type="submit"]:hover {
	opacity: 0.6;
}

span.wpcf7-not-valid-tip {
  margin-top: 10px;
  font-size: 12px !important;
}

div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ok {
  padding: 0 !important;
  font-size: 13px;
  line-height: 1.75;
	color: #f00;
  border: none !important;
}

div.wpcf7-response-output {
  margin: 2em 0 1em 0 !important;
}

.acf-map {
  width: 100%;
  height: 400px;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

span.wpcf7-list-item {
	display: block !important;
	margin: 0 0 18px 0 !important;
}

span.wpcf7-list-item label {
	cursor: pointer;
}

span.wpcf7-list-item label:hover .wpcf7-list-item-label {
	opacity: 0.6;
}
